/**
 * 防抖函数
 * @param {*} fn 目标函数
 * @param {*} wait 间隔时间
 * @returns
 */
export function debounce(fn, wait) {
  let timeout;
  return function() {
    let that = this;
    let arg = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      fn.apply(that, arg); //使用apply改变this指向
    }, wait);
  };
}

/**
 * 节流函数
 * @param {*} fn 目标函数
 * @param {*} wait 间隔时间
 * @returns
 */
export function throttle(fn, wait = 20) {
  let timeout;
  return function() {
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
        fn.apply(this, arguments);
      }, wait);
    }
  };
}
