<template>
  <div class="x-infinite" ref="container" :style="{ padding: padding }">
    <button @click="add">添加</button>
    <slot :sliceItems="sliceItems"></slot>

  </div>
</template>

<script>
import { throttle } from "@/utils/index";

function getScrollTop() {
  return document.documentElement.scrollTop || document.body.scrollTop;
}

export default {
  name: "x-infinite-list",
  props: {
    items: {
      required: true,
    },
    itemHeight: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      buffer: 10, // 列表滚动减少卡顿
      scrollTop: 0, // 滚动高度
      viewportHeight: 0, // 文档显示区的高度，
    };
  },
  computed: {
    over() {
      return Math.max(
        Math.floor(this.scrollTop / this.itemHeight) - this.buffer,
        0
      );
    },
    down() {
      return Math.min(
        Math.ceil(
          (this.scrollTop + this.viewportHeight) / this.itemHeight + this.buffer
        ),
        this.items.length
      );
    },
    sliceItems() {
      return this.items.slice(this.over, this.down); // 0 10 -> 0 14 -> 1 15
      /**
       * [1,2,3,4,5,6,7,8,9,10].slice(0,7) // [1, 2, 3, 4, 5, 6, 7]
       * [1,2,3,4,5,6,7,8,9,10].slice(1,8) // [2, 3, 4, 5, 6, 7, 8]
       * [1,2,3,4,5,6,7,8,9,10].slice(2,9) // [3, 4, 5, 6, 7, 8, 9]
       */
    },
    padding() {
      return `${this.over * this.itemHeight}px 0 ${Math.max(
        (this.items.length - this.down) * this.itemHeight,
        0
      )}px 0`;
    },
  },
  created() {
    this.scrollTop = getScrollTop();
    this.viewportHeight = window.innerHeight;

    document.addEventListener("scroll", this.onScroll, {
      passive: true,
    });
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll: throttle(function() {
      console.log("onScroll");
      this.scrollTop = getScrollTop();
      this.viewportHeight = window.innerHeight;
    }),

    add: throttle(function() {
      console.log("add",);
    },1000), 
  },
};
</script>

<style></style>
