// UTopic.vue
<template>
  <div>
    <x-infinite-list :items="items" :item-height="80" #default="{ sliceItems }">
      <x-list :items="sliceItems"></x-list>
    </x-infinite-list>
    <!-- <div class="x-bottom" v-intersect="{ handler: fetchNext }"></div> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import XList from "./components/x-list.vue";
import XInfiniteList from "./components/x-infinitelist.vue";
const { mapState, mapActions } = createNamespacedHelpers("topic");
export default {
  name: "x-top",
  props: ["type"],
  components: {
    XList,
    XInfiniteList,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    // ...mapState({
    //   items: (state) => state[state.activeType].items,
    // }),
  },
  created() {
    this.generateArray();
    // console.log(this.type);
    // this.fetchData({ type: this.type });
  },
  watch: {
    // type(type) {
    //   this.fetchData({ type });
    // },
  },
  methods: {
    // ...mapActions({
    //   fetchData: "FETCH_LIST_DATA",
    // }),
    fetchNext() {
      const { type } = this;
      this.fetchData({ type });
    },

    generateArray() {
      console.time("generate");
      for (let i = 1; i <= 100000; i++) {
        this.items.push({ title: `测试数据 -- ${i}/300` });
      }
      console.timeEnd("generate");
    },
  },
};
</script>
<style scoped>
.x-bottom {
  width: 100%;
  height: 1px;
}
</style>
