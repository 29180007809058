// components/UList
<template>
  <div class="x-list">
    <div v-for="(item, index) in items" :key="index">
      <x-item :node="item"></x-item>
    </div>
  </div>
</template>

<script>
import XItem from "./x-item.vue";

export default {
  name: "x-list",
  props: {
    items: {
      required: true
    }
  },
  components: {
    XItem
  }
};
</script>

<style>

</style>